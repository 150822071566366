import React, { lazy, Suspense } from 'react'
import ReactDOM from 'react-dom'
import moment from 'moment'
import 'moment/locale/es'
import 'antd/es/message/style/index.css'
import 'antd/dist/antd.css'
import './fonts/circular-std-medium-500.ttf'
import './fonts/Circular-Std-Bold.ttf'
import './fonts/CircularStd-Bold.woff2'
import './fonts/HandoSoftTrial-Bold.ttf'
import './fonts/NameSmile.otf'
import './index.css'
import SpinnerLoader from 'components/SpinerLoader'
//add spiner when load
const App = lazy(() => import(`./App`))

moment.locale(`es`)
ReactDOM.render(
  <Suspense fallback={<SpinnerLoader />}>
    <App />
  </Suspense>,
  document.getElementById(`root`),
)
